import React from "react"

// Components
import Section from "../common/section.component"
import Title from "../typography/title.component"
import { useStaticQuery, graphql } from "gatsby"

// Styles
import Styled from "./work-with-us.styled"
import Footer from "../common/footer.component"

const WorkWithUs = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(work-with-us.md)/" } }
      ) {
        edges {
          node {
            frontmatter {
              pageTitle
            }
            html
          }
        }
      }
    }
  `)

  const { pageTitle } = data.allMarkdownRemark.edges[0].node.frontmatter
  const { html } = data.allMarkdownRemark.edges[0].node

  return (
    <>
      <Styled.WorkWithUs>
        <Section>
          <Title blue level={3}>
            {pageTitle}
          </Title>

          <div dangerouslySetInnerHTML={{ __html: html }} />

          <div>
            <iframe
              src="https://app.acuityscheduling.com/schedule.php?owner=21717787"
              title="Schedule Appointment"
              width="100%"
              height={1700}
              frameBorder="0"
            ></iframe>
            <script
              src="https://embed.acuityscheduling.com/js/embed.js"
              type="text/javascript"
            ></script>
          </div>
        </Section>
      </Styled.WorkWithUs>
      <Footer heading="Let's Work Together" />
    </>
  )
}

export default WorkWithUs
