import React from "react"
import { Layout, SEO } from "src/components/common"

// Components
import WorkWithUs from "components/work-with-us/work-with-us.component"

const NotFoundPage = () => {
  // !: How to adjust height of iframe based on content?
  return (
    <Layout>
      <SEO title="Work With Us" />
      <WorkWithUs />
    </Layout>
  )
}

export default NotFoundPage
