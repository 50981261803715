import styled from "styled-components"

import Colors from "src/ui/colors"

const Styled = {
  WorkWithUs: styled.div`
    text-align: center;

    p {
      font-size: 24px;
      color: ${Colors.darkGray};
      line-height: 1.5;
      max-width: 975px;
      margin: 50px auto 100px;
      font-weight: 100;
      text-align: center;

      @media (max-width: 950px) {
        font-size: 20px;
        text-align: left;
        margin: 25px 0;
      }
    }
  `,
}

export default Styled
